import { graphql } from 'gatsby';
import React from 'react';

import PageSection from '@components/Layout/PageSection/PageSection';
import HelpListing from '@components/Listing/HelpListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Copy from '@components/Typography/Copy';
import H from '@components/Typography/H';

const ProductHelpPage = () => {
	return (
		<Page transparent={false}>
			<SEO
				title="Product help | UHV Design"
				description="Our technical sales team are here to support you with your enquiry and offer a solution for your requirements. Find product manuals and software downloads here."
			/>

			<PageSection>
				<div>
					<H level={2} as="h1">
						Product help
					</H>

					<Copy spacing="lg">
						Find product manuals, download software
					</Copy>
				</div>
			</PageSection>

			<PageSection background="grey">
				<HelpListing />
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		hero: file(name: { eq: "hero" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;

export default ProductHelpPage;
