import { LINKS, PRODUCT_CATEGORIES } from '@utils/constants';
import { SVGPicker } from '@utils/helpers';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';

import Grid from '@components/Layout/Grid/Grid';
import StripCard from '@components/Molecule/StripCard/StripCard';
import H from '@components/Typography/H';

const HelpListing = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					category: allPrismicProductCategory {
						edges {
							node {
								uid
								data {
									title
									codename
								}
							}
						}
					}
				}
			`}
			render={({ category }: { category: any }): JSX.Element => {
				category.edges.sort(
					(a, b) =>
						PRODUCT_CATEGORIES.indexOf(a.node.data.codename) -
						PRODUCT_CATEGORIES.indexOf(b.node.data.codename)
				);

				return (
					<div>
						<Grid gap="md">
							{category.edges.map(
								({ node }: { node: any }, key: number) => {
									const { uid } = node;
									const { title } = node.data;

									return uid && title ? (
										<StripCard
											id={key}
											to={`${LINKS.PRODUCT_HELP}/${uid}`}
											title={title}
											svg={SVGPicker(uid)}
											constrict
											key={key}>
											<H level={4} spacing="none">
												{title}
											</H>
										</StripCard>
									) : null;
								}
							)}
						</Grid>
					</div>
				);
			}}
		/>
	);
};

export default HelpListing;
